import React from 'react';
import { Skeleton } from '@mui/material';

const ProductCardSkeleton = () => (
  <div className="col-lg-3 col-md-6 col-sm-6 col-6">
    <div className="single-product-box">
      <div className="product-image">
        <Skeleton variant="rectangular" width="100%" height={200} />
      </div>
      <div className="product-content">
        <br/>
        <Skeleton variant="text" width="80%" height={24} />
        <Skeleton variant="text" width="60%" height={24} />
        <br/>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </div>
    </div>
  </div>
);

export default ProductCardSkeleton;
