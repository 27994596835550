import React, { useState, useEffect } from 'react';
import { 
  Box, TextField, Button, Typography, Card, 
  CardContent, Grid, CardActionArea, CircularProgress, Alert 
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add'; 
import useStore from '../../hooks/useStore'; 
import TopPanel from '../Top/TopPanel';
import TopHeader from '../Top/TopHeader';
import Navbar from '../Top/Navbar/Navbar';
import Footer from '../Footer';



const AddStore = () => {

  const [nombre, setNombre] = useState('');
  const [logo, setLogo] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [userStores, setUserStores] = useState([]); // Nuevo estado para guardar las tiendas
  const { registerStore, getStores, loading, error,authenticatedUser } = useStore(); 

  useEffect(() => {
    console.log("authenticatedUser",authenticatedUser);
    const fetchStores = async () => {
      const stores = await getStores();
      console.log('Tiendas recuperadas:', stores); 
      setUserStores(stores); // Guardar las tiendas en el estado local
    };
    fetchStores();
  }, [authenticatedUser]);

  const handleSubmit = async () => {
    if (!nombre || !logo) return alert('Por favor ingrese el nombre y suba un logo.');

    const result = await registerStore({ title: nombre, logo });
    result.success ? resetForm() : console.error('Error:', result.error);
  };

  const resetForm = () => {
    setNombre('');
    setLogo(null);
    setShowForm(false);
  };
 
  const redirigirTienda = (tienda) => {
    const dominioBase = process.env.REACT_APP_URL_REPLACE;
    const dominioBaseSSL = process.env.REACT_APP_URL_SSL;
    
    const url = `${dominioBaseSSL}://${tienda}.${dominioBase}`; 
    window.open(url, '_blank'); 
  };

  const redirigirTiendaAdmin = (tienda) => {
    const dominioBase = process.env.REACT_APP_URL_REPLACE; 
    const dominioBaseSSL = process.env.REACT_APP_URL_SSL;
    const url = `${dominioBaseSSL}://${dominioBase}/${tienda}/administrar`; 
     window.open(url, '_blank'); 
  };






  return (
    <>
      <TopPanel />
      <TopHeader />
      <Navbar />

      <Box sx={{ margin: '50px auto', padding: 3, maxWidth: 1200, minHeight: '500px' }}>
        <h1>Mis tiendas</h1>
        {loading && <CircularProgress />}
        {error && <Alert severity="error">{error}</Alert>}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Card 
              sx={{ height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center', 
                border: '1px solid #222', cursor: 'pointer', '&:hover': { backgroundColor: '#f1f1f1' } }}
              onClick={() => setShowForm(true)}
            >
              <CardActionArea sx={{ textAlign: 'center' }}>
                <AddIcon fontSize="large" />
                <Typography variant="subtitle1">Crear una tienda</Typography>
              </CardActionArea>
            </Card>
          </Grid>

          {userStores.length === 0 ? (
            <Typography variant="h6" align="center" sx={{ width: '100%', marginTop: 2 }}>
              No tienes tiendas registradas.
            </Typography>
          ) : (
            userStores.map((store, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
  <Card
    sx={{
      height: 150,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': { backgroundColor: '#f1f1f1' },
    }}
    onClick={() => redirigirTienda(store)}
  >
    <CardContent>
      <Typography variant="h6" align="center">
        {store}
      </Typography>
    </CardContent>
  </Card>

  <div
    onClick={() => redirigirTiendaAdmin(store)}
    style={{
      textAlign: 'center',
      marginTop: '10px', // Espacio entre la tarjeta y el enlace
      cursor: 'pointer', // Indicador de clic
      fontWeight: 'bold', // Opcional: Para resaltar el texto
    }}
  >
    administrar {store}
  </div>
</Grid>

            ))
          )}
        </Grid>
      </Box>

      {showForm && (
        <Box 
          onClick={e => e.target.id === 'overlay' && setShowForm(false)}
          id="overlay"
          sx={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', 
            backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}
        >
          <Box 
            sx={{ maxWidth: 400, margin: '30px auto', padding: 3, position: 'absolute', 
              top: '30%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', boxShadow: 24 }}
          >
            <Typography variant="h6" gutterBottom align="center">Agregar Tienda</Typography>

            <TextField 
              fullWidth label="Nombre de la tienda" margin="normal" 
              value={nombre} onChange={e => setNombre(e.target.value)} 
            />

            <Button variant="outlined" component="label" fullWidth sx={{ marginTop: 2 }}>
              Subir Logo
              <input type="file" hidden accept="image/*" onChange={e => setLogo(e.target.files[0])} />
            </Button>

            {logo && (
              <Box sx={{ marginTop: 2, textAlign: 'center' }}>
                <img src={URL.createObjectURL(logo)} alt="Logo Preview" style={{ maxWidth: '100%' }} />
              </Box>
            )}

            <Button 
              fullWidth variant="contained" color="primary" 
              sx={{ marginTop: 2 }} onClick={handleSubmit} disabled={loading}
            >
              {loading ? 'Agregando...' : 'Agregar'}
            </Button>
          </Box>
        </Box>
      )}
      
      <Footer />
    </>
  );
};

export default AddStore;
