// src/components/TopHeaderRightNav.js
import React from 'react';

const TopHeaderRightNav = () => (
  <ul className="top-header-right-nav">
      {/*
    <li>
      <a href="product-type-2.html" data-bs-toggle="modal" data-bs-target="#shoppingWishlistModal">
        Wishlist <i className="far fa-heart"></i>
      </a>
    </li>
    <li>
      <a href="compare.html">
        Compare <i className="fas fa-balance-scale"></i>
      </a>
    </li>
    <li>
      <div className="languages-list">
        <select>
          <option value="1">Eng</option>
          <option value="2">Ger</option>
          <option value="3">Span</option>
        </select>
      </div>
    </li>
          */}
  </ul>
);

export default TopHeaderRightNav;
