// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import MarketTema1 from './pages/MarketTema1';
import Login from './pages/Login';
import DetalleProducto from './components/DetalleProducto/DetalleProducto';
import AddStore from './components/AddStore/AddStore';

function App() {
  

  const urlReplace = process.env.REACT_APP_URL_REPLACE;
  const storeName = window.location.hostname.replace('www.', '').replace(urlReplace, "").replace(".", "").trim();


  const [productos, setProductos] = useState([]);
  return (

    <Router>
      <div className="App">

        <Routes>
          <Route path="/"  element={storeName === '' || storeName==='localhost' ? <Login /> : <MarketTema1 />}  />
          
          <Route path="/market" element={<MarketTema1 />} />

          <Route 
            path="/:tienda/administrar" 
            element={<MarketTema1/>} 
          />

          <Route 
            path="/detalle_producto/:id" 
            element={<DetalleProducto productos={productos} />} 
          />
          
          <Route path="/admi_tienda" element={<AddStore />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
