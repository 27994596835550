// src/components/Footer.js
import React, { useState, useEffect } from 'react';
import { Box, Fab, Tooltip, Modal, TextField, Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FooterWidget from './FooterWidget';
import FooterContactInfo from './FooterContactInfo';
import { auth } from '../firebaseConfig';
import { saveToFirestore, getAuthenticatedUser, uploadFile } from '../utils/firebaseUtils';
import LZString from 'lz-string';


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  maxHeight: '90vh', // Limit modal height
  overflow: 'auto', // Enable scroll if content overflows
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const Footer = ({ compressedFooter }) => {
  const [user, setUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [footerContent, setFooterContent] = useState({
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    copyright: '© Comero is Proudly Owned by EnvyTheme',
    quickLinks: [
      { href: 'about.html', text: 'About Us' },
      { href: 'faq.html', text: "FAQ's" },
      { href: 'customer-service.html', text: 'Customer Services' },
      { href: 'contact-us.html', text: 'Contact Us' },
    ],
    information: [
      { href: 'about.html', text: 'About Us' },
      { href: 'faq.html', text: "FAQ's" },
      { href: 'customer-service.html', text: 'Customer Services' },
      { href: 'contact-us.html', text: 'Contact Us' },
    ],
    contact: {
      location: '2750 Quadra Street Victoria, Canada',
      phone: '(+123) 456-7898',
      email: 'support@comero.com',
      fax: '+123456',
    },
  });

  const [footerData, setFooterData] = useState(null);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  console.log("footer comprimido",compressedFooter);

  useEffect(() => {
    if (compressedFooter) {
      try {
        // Descomprime los datos del footer usando decompressFromBase64
        const decompressedData = LZString.decompressFromBase64(compressedFooter);
        if (decompressedData) {
          const parsedData = JSON.parse(decompressedData);
          // Actualiza directamente footerContent con los datos descomprimidos
          setFooterContent({
            ...footerContent,
            ...parsedData,
          });
        } else {
          console.error('Error al descomprimir el footer: decompressedData es null.');
        }
      } catch (error) {
        console.error('Error al descomprimir el footer:', error);
      }
    } else {
      console.log('No se recibió compressedFooter.');
      // Restablecer a valores por defecto
      setFooterContent(footerContent);
    }
  }, [compressedFooter]);
  



  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFooterContent((prev) => ({ ...prev, [name]: value }));
  };

  const handleContactChange = (field, value) => {
    setFooterContent((prev) => ({
      ...prev,
      contact: { ...prev.contact, [field]: value },
    }));
  };

  const handleLinkChange = (category, index, field, value) => {
    const updatedLinks = [...footerContent[category]];
    updatedLinks[index][field] = value;
    setFooterContent((prev) => ({ ...prev, [category]: updatedLinks }));
  };

  const handleAddLink = (category) => {
    setFooterContent((prev) => ({
      ...prev,
      [category]: [...prev[category], { href: '', text: '' }],
    }));
  };

  const handleRemoveLink = (category, index) => {
    const updatedLinks = footerContent[category].filter((_, i) => i !== index);
    setFooterContent((prev) => ({ ...prev, [category]: updatedLinks }));
  };


  const handleSave = async () => {
    try {
      const footerJson = JSON.stringify(footerContent);
      const footer = LZString.compressToBase64(footerJson);  // Comprimir los datos
  
      // Obtener solo el subdominio
      const storeName = window.location.hostname.split('.')[0];
      await saveToFirestore(storeName, 'config', { footer });
      console.log("tienda", storeName);
      console.log('Footer guardado con éxito');
      handleCloseModal();
    } catch (error) {
      console.error('Error al guardar el footer:', error);
    }
  };
  
  


  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <FooterWidget
            title={null}
            content={
              <>
                <div className="logo">
                  <a href="index.html">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/img/Millev.png`}
                      alt="logo"
                      style={{ width: '98px', height: '20px' }}
                    />
                  </a>
                </div>
                <p>{footerContent.description}</p>
              </>
            }
          />
          <FooterWidget title="Quick Links" links={footerContent.quickLinks} />
          <FooterWidget title="Information" links={footerContent.information} />
          <FooterWidget
            title="Contact Us"
            links={[
              { href: 'https://goo.gl/maps/xyz', text: footerContent.contact.location },
              { href: `tel:${footerContent.contact.phone}`, text: `Call Us: ${footerContent.contact.phone}` },
              { href: `mailto:${footerContent.contact.email}`, text: `Email Us: ${footerContent.contact.email}` },
              { href: `tel:${footerContent.contact.fax}`, text: `Fax: ${footerContent.contact.fax}` },
            ]}
          />
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>{footerContent.copyright}</p>
            </div>
            <div className="col-lg-6 col-md-6">
              <ul className="payment-card">
                {['visa.png', 'mastercard.png', 'mastercard2.png', 'visa2.png', 'expresscard.png'].map(
                  (image, index) => (
                    <li key={index}>
                      <a href="#" target="_blank" rel="noopener noreferrer">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/img/${image}`}
                          alt="payment"
                        />
                      </a>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {user && (
        <Box sx={{ position: 'absolute', bottom: '10em', right: 16 }}>
          <Tooltip title="Editar Footer" arrow>
            <Fab color="secondary" onClick={handleOpenModal}>
              <EditIcon />
            </Fab>
          </Tooltip>
        </Box>
      )}
      
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <h2>Editar Footer</h2>
          <TextField
            fullWidth
            margin="normal"
            label="Descripción"
            name="description"
            value={footerContent.description}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Copyright"
            name="copyright"
            value={footerContent.copyright}
            onChange={handleChange}
          />
          {['quickLinks', 'information'].map((category) => (
            <div key={category}>
              <h3>{category === 'quickLinks' ? 'Quick Links' : 'Information'}</h3>
              {footerContent[category].map((link, index) => (
                <Box key={index} display="flex" alignItems="center" mb={1}>
                  <TextField
                    label="Texto"
                    value={link.text}
                    onChange={(e) => handleLinkChange(category, index, 'text', e.target.value)}
                    sx={{ mr: 1 }}
                  />
                  <TextField
                    label="URL"
                    value={link.href}
                    onChange={(e) => handleLinkChange(category, index, 'href', e.target.value)}
                    sx={{ mr: 1 }}
                  />
                  <IconButton onClick={() => handleRemoveLink(category, index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button startIcon={<AddIcon />} onClick={() => handleAddLink(category)} sx={{ mb: 2 }}>
                Agregar Enlace
              </Button>
            </div>
          ))}

          <h3>Contact Us</h3>
          <TextField
            fullWidth
            margin="normal"
            label="Ubicación"
            value={footerContent.contact.location}
            onChange={(e) => handleContactChange('location', e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Teléfono"
            value={footerContent.contact.phone}
            onChange={(e) => handleContactChange('phone', e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            value={footerContent.contact.email}
            onChange={(e) => handleContactChange('email', e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Fax"
            value={footerContent.contact.fax}
            onChange={(e) => handleContactChange('fax', e.target.value)}
          />

          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={handleCloseModal} sx={{ mr: 1 }}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Guardar
            </Button>
          </Box>

        </Box>
      </Modal>
    </footer>
  );
};

export default Footer;
