// src/App.js
import React from 'react';
import TopPanel from '../components/Top/TopPanel';
import TopHeader from '../components/Top/TopHeader';
import Navbar from '../components/Top/Navbar/Navbar';
import MainBanner from '../components/MainBanner';
import OfferSection from '../components/OfferSection';
import ProductsArea from '../components/Producto/ProductsArea';
import FacilityArea from '../components/FacilityArea';
import Footer from '../components/Footer';

import LoginArea from '../components/Login/LoginArea';

const MarketTema1 = () => (
    <div>
      <TopPanel />
      <TopHeader />
      <Navbar />
      <LoginArea />
      <FacilityArea />
      <Footer />
    </div>
);

export default MarketTema1;
