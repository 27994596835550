import React from 'react';
import { Skeleton, Box, Typography, Button } from '@mui/material';

const BannerSkeleton2 = () => {
    
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" p={4} bgcolor="white">
      <Box display="flex" gap={4} maxWidth="xl">
        
        {/* Sección de texto */}
        <Box maxWidth="sm" textAlign="left">
          <Typography variant="h1">
            <Skeleton width="60%" height={45} />
          </Typography>
          <Skeleton width="80%" height={35} />
          <Box mt={2}>
            <Skeleton width="100%" height={20} />
            <Skeleton width="90%" height={20} />
            <Skeleton width="70%" height={20} />
          </Box>
          <Box mt={3} display="flex" gap={2}>
            <Skeleton variant="rectangular" width={130} height={40} />
            <Skeleton variant="rectangular" width={130} height={40} />
          </Box>
        </Box>

        {/* Columna de imagen */}
        <Box width={384} height={320} display="flex" alignItems="center" justifyContent="center" bgcolor="gray.200" borderRadius={2} boxShadow={3}>
          <Skeleton variant="rectangular" width="100%" height="100%" />
        </Box>

      </Box>
    </Box>
  );
};

export default BannerSkeleton2;
