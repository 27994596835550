// src/components/NewCustomer.js
import React from 'react';

const NewCustomer = () => (
  <div className="new-customer-content">
    <div className="section-title">
      <h2>
        <span className="dot"></span> New Customer
      </h2>
    </div>
    <span>Create an Account</span>
    <p>
      Sign up for a free account at our store. Registration is quick and easy. It allows you to
      be able to order from our shop. To start shopping click register.
    </p>
    <a href="#" className="btn btn-light">
      Create A Account
    </a>
  </div>
);

export default NewCustomer;
