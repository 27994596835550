// src/components/MobileNav.js
import React from 'react';

const MobileNav = () => (
  <div className="comero-mobile-nav">
    <div className="logo">
      <a href="index.html">
        <img src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} alt="logo" />
      </a>
    </div>
  </div>
);

export default MobileNav;
