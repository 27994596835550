// src/components/MainBanner2.js
import React, { useState, useEffect } from 'react';
import {
  Fab,
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  InputLabel,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { saveToFirestore, getAuthenticatedUser, uploadFile } from '../../utils/firebaseUtils';
import LZString from 'lz-string';
import BannerSkeleton2 from './BannerSkeleton2';
import { auth } from '../../firebaseConfig';
import { useNavigate , useLocation , useParams} from 'react-router-dom'; 
import BannerEditorModal from '../common/BannerEditorModal/BannerEditorModal';


const MainBanner2 = ({ compressedBanner,refreshStoreConfig }) => {

  const urlReplace = process.env.REACT_APP_URL_REPLACE;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { tienda } = useParams();
  const isAdminRoute = location.pathname.includes(`${tienda}/administrar`);
  const [storeName, setStoreName] = useState();
  

  // Captura el subdominio de la URL para obtener el nombre de la tienda
  const getSubdomain = () => {
    if(isAdminRoute){
      setStoreName(tienda);
    }else{
      const hostname = window.location.hostname.replace('www.', '').replace(urlReplace, "").replace(".", "");
      setStoreName(hostname);
    }
  };

  // Cargar productos al iniciar el componente
  useEffect(() => {
    getSubdomain();
  }, []);

  // Valores por defecto para bannerData
  const defaultBannerData = {
    title: 'Información Accesible y Controlada para Todos',
    subtitle: '¡Ropa hecha para ti!',
    description:
      'Plataforma intuitiva, escalable, segura y unificada para la visualización, descarga y edición de reportes y documentos.',
    image: 'assets/img/default-banner.jpg', // Imagen por defecto
    buttonText: 'Comenzar ahora',
    buttonColor: '#1976d2',
    buttonTextColor: '#ffffff',
    buttonAction: 'https://example.com',
    subtitleColor: '#000000',
    titleColor: '#000000',
    descriptionColor: '#000000',
    bannerType: 'MainBanner2', // Nuevo campo
  };

  const [bannerData, setBannerData] = useState(defaultBannerData);
  const [previewImage, setPreviewImage] = useState(defaultBannerData.image);

  // Descomprimir compressedBanner cuando cambie
  useEffect(() => {
    console.log('Compressed banner received2:', compressedBanner);
    if (compressedBanner) {
      try {
        const decompressedData = LZString.decompressFromBase64(compressedBanner);
        console.log('Decompressed banner data2:', decompressedData);
        if (decompressedData) {
          const parsedData = JSON.parse(decompressedData);
          console.log('Parsed banner data2:', parsedData);
          const bannerData = {
            ...defaultBannerData,
            ...parsedData,
            bannerType: parsedData.bannerType || defaultBannerData.bannerType,
          };
          setBannerData(bannerData);
          setPreviewImage(bannerData.image || defaultBannerData.image);
          console.log('Final banner data2:', bannerData);
        } else {
          console.error('Error al descomprimir el banner: decompressedData es null.');
        }
      } catch (error) {
        console.error('Error al descomprimir el banner:', error);
      }
    } else {
      console.log('No se recibió compressedBanner2.');
      setBannerData(defaultBannerData);
      setPreviewImage(defaultBannerData.image);
    }
  }, [compressedBanner]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Change detected - Name2: ${name}, Value: ${value}`);
    setBannerData((prev) => ({ ...prev, [name]: value }));
    console.log('Updated bannerData2:', bannerData);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setPreviewImage(imageUrl);
      setBannerData((prev) => ({ ...prev, image: file }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      console.log('Data to save in banner (including bannerType):', bannerData);
      const userEmail = getAuthenticatedUser();
      const userId = userEmail.replace('@', '_').replace('.', '_');

      let imageUrl = bannerData.image;

      if (bannerData.image instanceof File) {
        // Sube la imagen utilizando la función uploadFile
        imageUrl = await uploadFile(bannerData.image, 'banners');
      }

      const bannerToSave = {
        ...bannerData,
        image: imageUrl,
      };

      // Convierte los datos del banner a una cadena JSON
      console.log('Data to save in banner2:', bannerData);
      const bannerJson = JSON.stringify(bannerToSave);

      // Comprime la cadena JSON utilizando compressToBase64
      const banner = LZString.compressToBase64(bannerJson);

      console.log('banner comprimido2:', banner);

      //const storeName = window.location.hostname.replace('www.', '');
      // Guarda en Firestore utilizando saveToFirestore
      await saveToFirestore(storeName, 'config', {banner});

      // Actualiza el estado local con los datos guardados
      setBannerData(bannerToSave);
      setPreviewImage(imageUrl);

      console.log('Banner guardado con éxito');
      handleClose();

      console.log('Checking if refreshStoreConfig is called2');
      if (refreshStoreConfig) {
        refreshStoreConfig();
        console.log('refreshStoreConfig function is not available2');
      }
    } catch (error) {
      console.error('Error al guardar el banner:', error);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <BannerSkeleton2 />
  ) : (
    <div className="w-full flex items-center justify-center bg-white p-8 relative h-lvh">
      {/* Sección de texto */}
      <div className="max-w-md text-left">
        <span style={{ color: bannerData.subtitleColor }}>{bannerData.subtitle}</span>
        <h1 className="text-4xl font-bold" style={{ color: bannerData.titleColor }}>
          {bannerData.title}
        </h1>
        <p className="mt-4" style={{ color: bannerData.descriptionColor }}>
          {bannerData.description}
        </p>
        <div className="mt-6 flex gap-4">
          <a
            href={bannerData.buttonAction}
            className="px-6 py-2 font-semibold rounded-lg shadow-md transition duration-300"
            style={{
              backgroundColor: bannerData.buttonColor,
              color: bannerData.buttonTextColor,
            }}
          >
            {bannerData.buttonText}
          </a>
          {/* Puedes agregar otro botón o enlace aquí si es necesario */}
        </div>
      </div>

      {/* Columna de imagen */}
      <div className="w-96 h-80 bg-gray-200 rounded-lg flex items-center justify-center shadow-md shadow-lg ml-8">
        <img
          src={previewImage || defaultBannerData.image}
          alt="Imagen del Banner"
          className="w-full h-full object-cover rounded-lg"
        />
      </div>

      
      {user && (
          <Tooltip title="Editar Banner" arrow>
            <Fab color="primary" aria-label="edit" onClick={handleOpen}>
              <EditIcon />
            </Fab>
          </Tooltip>
        )}

        <BannerEditorModal
          open={open}
          handleClose={handleClose}
          bannerData={bannerData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleImageChange={handleImageChange}
          loading={loading}
        />
    </div>
  );
};

export default MainBanner2;