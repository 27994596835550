// src/components/FacilityBox.js
import React from 'react';

const FacilityBox = ({ icon, title }) => (
  <div className="col-lg-3 col-md-6 col-sm-6">
    <div className="facility-box">
      <div className="icon">
        <i className={icon}></i>
      </div>
      <h3>{title}</h3>
    </div>
  </div>
);

export default FacilityBox;
