// src/components/TopHeader.js
import React from 'react';
import TopHeaderNav from './TopHeaderNav';
import TopHeaderRightNav from './TopHeaderRightNav';

const TopHeader = () => (
  <div className="top-header">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-7 col-md-6">
          <TopHeaderNav />
        </div>
        <div className="col-lg-5 col-md-6">
          <TopHeaderRightNav />
        </div>
      </div>
    </div>
  </div>
);

export default TopHeader;
