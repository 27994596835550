import React, { useEffect, useState } from 'react';
import { CircularProgress, Alert } from '@mui/material';
import TopPanel from '../components/Top/TopPanel';
import TopHeader from '../components/Top/TopHeader';
import Navbar from '../components/Top/Navbar/Navbar';
import MainBanner from '../components/Banner/MainBanner';
import MainBanner2 from '../components/Banner/MainBanner2';
import ProductsArea from '../components/Producto/ProductsArea';
import FacilityArea from '../components/FacilityArea';
import Footer from '../components/Footer';
import useStore from '../hooks/useStore'; // Importar el hook
import BannerSkeleton from '../components/Banner/BannerSkeleton'; // Importar BannerSkeleton
import BannerSkeleton2 from '../components/Banner/BannerSkeleton2';
import Blog from '../components/Blog/Blog';
import { doc, getDoc } from 'firebase/firestore'; // Firebase imports
import { db } from '../firebaseConfig'; // Firebase config
import { useParams } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import LZString from 'lz-string';
import Servicios from '../components/Servicios/Servicios';


const MarketTema1 = () => {
  const urlReplace = process.env.REACT_APP_URL_REPLACE;
  const [storeConfig, setStoreConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const { tienda } = useParams();
  const isAdminRoute = location.pathname.includes(`${tienda}/administrar`);
  const [storeName, setStoreName] = useState();
  console.log("otra prueba 123")

  /*const getSubdomain = () => {
    if(isAdminRoute){
      return tienda;
    }else{
      const hostname = window.location.hostname;
      const parts = hostname.split('.');
      if (parts.length > 2) {
        return parts[0]; // Retorna el subdominio (nombre de la tienda)
      }
    }
    return null;
  };*/

  const getSubdomain = () => {
    if(isAdminRoute){
      setStoreName(tienda);
    }else{
      const hostname = window.location.hostname.replace('www.', '').replace(urlReplace, "").replace(".", "");
      setStoreName(hostname);
    }
  };

  useEffect(() => {
    getSubdomain();
  }, []);

  //const storeName = getSubdomain(); 
  //const storeName = tienda;
  console.log('storeName111',storeName);

  /*const fetchStoreConfig = async () => {
    try {
      const storeDocRef = doc(db, storeName, 'config'); 
      const storeDoc = await getDoc(storeDocRef); 
      if (storeDoc.exists()) {
        setStoreConfig(storeDoc.data()); 
        console.log('Configuración de la tienda:', storeDoc.data());
      } else {
        throw new Error('No se encontró la configuración de la tienda.');
      }
    } catch (err) {
      console.error('Error al obtener la configuración de la tienda:', err);
      setError(`Error al obtener los datos: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };*/

  const fetchStoreConfig = async () => {
    try {
      const storeDocRef = doc(db, storeName, 'config');
      const storeDoc = await getDoc(storeDocRef);
      if (storeDoc.exists()) {
        const data = storeDoc.data();
        console.log('Datos de configuración de la tienda:', data);
        setStoreConfig(data);
      } else {
        throw new Error('No se encontró la configuración de la tienda.');
      }
    } catch (err) {
      console.error('Error al obtener la configuración de la tienda:', err);
      setError(`Error al obtener los datos: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };
  

  
  useEffect(() => {
    if (storeName) {
      fetchStoreConfig();
    } /*else {
      setError('No se pudo determinar el nombre de la tienda.');
      setLoading(false);
    }*/
  }, [storeName]); 


  const refreshStoreConfig = async () => {
    await fetchStoreConfig();
  };


  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;



  let bannerComponent;

  if (storeConfig.banner) {
    const decompressedData = LZString.decompressFromBase64(storeConfig.banner);
    console.log('Decompressed store banner:', decompressedData);
    if (decompressedData) {
      const bannerData = JSON.parse(decompressedData);
      const bannerType = bannerData.bannerType ;
      console.log('Banner type detected:', bannerType);

      if (bannerType === 'MainBanner') {
        bannerComponent = <MainBanner compressedBanner={storeConfig.banner} refreshStoreConfig={fetchStoreConfig} />;
      } else if (bannerType === 'MainBanner2') {
        bannerComponent = <MainBanner2 compressedBanner={storeConfig.banner} refreshStoreConfig={fetchStoreConfig}/>;
      } else {
        // Banner por defecto
        bannerComponent = <MainBanner compressedBanner={storeConfig.banner} refreshStoreConfig={fetchStoreConfig}/>;
      }
    } else {
      console.error('Error al descomprimir los datos del banner.');
      // Renderizar un banner por defecto o skeleton
      bannerComponent = <MainBanner compressedBanner={storeConfig.banner} refreshStoreConfig={fetchStoreConfig}/>;
    }
  } else {
    // Si no hay datos del banner disponibles
    bannerComponent = <MainBanner compressedBanner={storeConfig.banner} refreshStoreConfig={fetchStoreConfig}/>;
  }

  bannerComponent = React.cloneElement(bannerComponent, { refreshStoreConfig });

  return (
    <div>
      <TopPanel />
      <TopHeader />
      <Navbar />
      
      {/* 
      {storeConfig.banner ? (
        <MainBanner compressedBanner={storeConfig.banner} />
      ) : (
        <BannerSkeleton />
      )}

      {storeConfig.banner ? (
        <MainBanner2 compressedBanner={storeConfig.banner} />
      ) : (
        <BannerSkeleton2 />
      )}
      */}

      {bannerComponent}
      <Servicios />

      <ProductsArea />
      <Blog />
      <FacilityArea />
      
      {storeConfig && <Footer compressedFooter={storeConfig.footer} />} 


    </div>
  );
};

export default MarketTema1;