import React from 'react';
import { Skeleton } from '@mui/material';

const BannerSkeleton = () => {
  return (
    <div className="main-banner">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="main-banner-content">
              <Skeleton variant="text" width="60%" height={40} />
              <Skeleton variant="text" width="80%" height={70} />
              <Skeleton variant="text" width="90%" height={30} />
              <Skeleton variant="rectangular" width={200} height={50} />
            </div>
          </div>
        </div>
      </div>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={600}
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
    </div>
  );
};

export default BannerSkeleton;
