import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDocumentById } from '../../utils/firebaseUtils';
import LZString from 'lz-string';
import { CircularProgress, Box, Typography } from '@mui/material';
import InfoProducto from './InfoProducto';
import TopPanel from '../Top/TopPanel';
import TopHeader from '../Top/TopHeader';
import Navbar from '../Top/Navbar/Navbar';
import Footer from '../Footer';
import ProductGallery from './ProductGallery';



const DetalleProducto = () => {
    const urlReplace = process.env.REACT_APP_URL_REPLACE;
    const { id } = useParams(); 
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const storeName = window.location.hostname.replace('www.', '').replace(urlReplace, "").replace(".", "");
  
    useEffect(() => {
      const fetchProduct = async () => {
        try {
          const collectionPath = `${storeName}/inventario/productos`;
          const productData = await getDocumentById(collectionPath, id);
  
          if (productData) {
            let additionalData = {};
            if (productData.data) {
              const decompressedData = LZString.decompressFromBase64(productData.data);
              additionalData = JSON.parse(decompressedData);
            }
  
            setProduct({
              id: productData.id,
              name: productData.name,
              productType: productData.productType,
              ...additionalData,
            });
          } else {
            console.error('Producto no encontrado');
          }
        } catch (error) {
          console.error('Error al cargar el producto:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchProduct();
    }, [id, storeName]);
  
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
          <CircularProgress />
        </Box>
      );
    }
  
    if (!product) {
      return <Typography variant="h6">Producto no encontrado</Typography>;
    }
  
console.log(product)

    

  return (

    <>
      <TopPanel />
      <TopHeader />
      <Navbar />

    

    <section class="facility-area">
      <div class="container">
          <div className="row">
            {/* Galería de Productos */}
            <div className="col-lg-6 col-md-6">
              {/*
              
              <div className="products-page-gallery">
                <div className="product-page-gallery-main">
                  {product.images.map((img, index) => (
                    <div className="item" key={index}>
                      <img src={img} alt={img} />
                    </div>
                  ))}
                </div>

                <div className="product-page-gallery-preview">
                  {product.images.map((img, index) => (
                    <div className="item" key={index}>
                      <img src={img} alt={img} />
                    </div>
                  ))}
                </div>
              </div>
*/}
              <ProductGallery product={product} />
            </div>

            {/* Detalles del Producto */}
            <div className="col-lg-6 col-md-6 ">
              <div className="product-details-content" style={{ textAlign: 'left' }}>
                <h3>{product.name}</h3>

                <div className="price">
                  <span className="new-price">S/.{product.price}</span>
                </div>



                <p>{product.description}</p>

                <ul className="product-info">
                  <li><span>Vendor:</span> <a href="#">{product.vendor}</a></li>
                  <li><span>Product Type:</span> <a href="#">{product.productType}</a></li>
                </ul>

                <div className="product-color-switch">
                  <h4>Color:</h4>
                  <ul>
                  {product.colors.map((color, index) => (
                                            <li key={color}>
                                              <a 
                                                href="#" 
                                                title={color} 
                                                style={{ backgroundColor: color }} // Estilo dinámico para el fondo
                                                className={`color-${color.replace('#', '')}`} // Clase opcionalmente generada
                                              >
                                                
                                              </a>
                                            </li>
                                          ))}
                  </ul>
                </div>

                <div className="product-size-wrapper">
                  <h4>Size:</h4>
                  <ul>
                  {product.sizes.map((size, index) => (
                              <li key={size} className={index === 1 ? 'active' : ''}>
                                <a href="#">{size}</a>
                              </li>
                            ))}
                  </ul>
                </div>

                <div className="product-info-btn">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#sizeGuideModal">
                    <i className="fas fa-crop"></i> Size guide
                  </a>
                  <a href="#" data-bs-toggle="modal" data-bs-target="#productShippingModal">
                    <i className="fas fa-truck"></i> Shipping
                  </a>
                
                </div>




                <div className="buy-checkbox-btn">
                  <div className="item">
                    <input className="inp-cbx" id="cbx" type="checkbox" />
                    <label className="cbx" htmlFor="cbx">
                      <span>
                        <svg width="12px" height="10px" viewBox="0 0 12 10">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span>I agree with the terms and conditions</span>
                    </label>
                  </div>

                  <div className="item">
                    <a href="#" className="btn btn-primary">Compra ahora</a>
                  </div>
                </div>


              </div>
            </div>
            {/* Info adicional del Producto */}
            <div className="col-lg-12 col-md-12">
                  <InfoProducto descripcion={product.description} color={product.colors} size={product.sizes}/>
            </div>
          </div>
      </div>
    </section>
    <Footer/>

    </>
  );
};

export default DetalleProducto;


