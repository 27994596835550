// src/components/TopPanel.js
import React from 'react';
import TopPanelSlides from './TopPanelSlides';

const TopPanel = () => (
  <div className="top-panel">
    <div className="container">
      <div className="panel-content">
        <TopPanelSlides />
      </div>
    </div>
  </div>
);

export default TopPanel;
