// src/components/ProductCard.js
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate } from 'react-router-dom';


const ProductCard = ({ product, onEdit , onDelete }) => {

  const navigate = useNavigate();
 

  const { id, images, name, price, oldPrice , paymentLink} = product;
  
  const handleCardClick = () => {
    navigate(`/detalle_producto/${id}`); 
  };
  

  return (
    <div className="col-lg-3 col-md-6 col-sm-6 col-6" >
      <div className="single-product-box">
        <div className="product-image">
         <div onClick={handleCardClick}>

              <Link to={`/detalle_producto/${id}`}>
                    <a href="product-type-3.html">
                      {images && images.length > 0 ? (
                        <img src={images[0]} alt={name} />
                      ) : (
                        <img
                        src={`${process.env.PUBLIC_URL}/assets/img/placeholder.png`}
                        alt="Placeholder"
                        />
                      )}
                    </a>
                </Link>
          </div>
          
          <ul>
            <li>
              <a href="#" title="Quick View">
                <i className="far fa-eye"></i>
              </a>
            </li>
            
            <li>
              <a href="#" title="Add to Wishlist">
                <i className="far fa-heart"></i>
              </a>
            </li>
            <li>
              <a href="#" title="Add to Compare">
                <i className="fas fa-sync"></i>
              </a>
            </li>
            <li>
              <a href="#" onClick={() => onEdit(product)} title="Edit Product">
                <EditIcon />
              </a>
            </li>
            <li>
              <a href="#" onClick={() => onDelete(product.id)} title="Eliminar Producto">
                <DeleteIcon />
              </a>
            </li>
          </ul>
        </div>


        <div className="product-content">
          <h3>{name}</h3>
          <div className="product-price">
            {oldPrice && <span className="old-price">${oldPrice}</span>}
            <span className="new-price">${price}</span>
          </div>
          <a href={paymentLink} target="_blank" className="btn btn-light">
          Comprar Ahora
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
