// src/components/MainBanner.js
import React, { useState, useEffect } from 'react';
import { Fab, Modal, Box, TextField, Button, Typography, Grid, InputLabel, Tooltip,FormControl, Select, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { saveToFirestore, getAuthenticatedUser, uploadFile } from '../../utils/firebaseUtils';
import LZString from 'lz-string';
import BannerSkeleton from './BannerSkeleton';
import { auth } from '../../firebaseConfig';
import { useNavigate , useLocation , useParams} from 'react-router-dom'; 
import BannerEditorModal from '../common/BannerEditorModal/BannerEditorModal';


const MainBanner = ({ compressedBanner,refreshStoreConfig  }) => {
  
  const urlReplace = process.env.REACT_APP_URL_REPLACE;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null); 
  const navigate = useNavigate();
  const location = useLocation();
  const { tienda } = useParams();
  const isAdminRoute = location.pathname.includes(`${tienda}/administrar`);
  const [storeName, setStoreName] = useState();
  

  // Captura el subdominio de la URL para obtener el nombre de la tienda
  const getSubdomain = () => {
    if(isAdminRoute){
      setStoreName(tienda);
    }else{
      const hostname = window.location.hostname.replace('www.', '').replace(urlReplace, "").replace(".", "");
      setStoreName(hostname);
    }
  };

  // Cargar productos al iniciar el componente
  useEffect(() => {
    getSubdomain();
  }, []);

  // Valores por defecto para bannerData
  const defaultBannerData = {
    title: 'Clothing made for you!',
    subtitle: 'New Inspiration 2024',
    description: 'Trending from men and women style collection',
    image: 'assets/img/default-banner.jpg', // Imagen por defecto
    buttonText: "Shop Women's",
    buttonColor: '#1976d2',
    buttonTextColor: '#ffffff',
    buttonAction: 'https://example.com',
    subtitleColor: '#000000',
    titleColor: '#000000',
    descriptionColor: '#000000',
    bannerType: 'MainBanner',
  };
  
  const [bannerData, setBannerData] = useState(defaultBannerData);
  const [previewImage, setPreviewImage] = useState(defaultBannerData.image);
  
  // Descomprimir compressedBanner cuando cambie
  useEffect(() => {
    console.log('Compressed banner received:', compressedBanner);
    if (compressedBanner) {
      try {
        // Utiliza decompressFromBase64 en lugar de decompressFromUTF16
        const decompressedData = LZString.decompressFromBase64(compressedBanner);
        console.log('Decompressed banner data:', decompressedData);
        if (decompressedData) {
          const parsedData = JSON.parse(decompressedData);
          console.log('Parsed banner data:', parsedData);
          const bannerData = {
            ...defaultBannerData,
            ...parsedData,
            image: parsedData.image || defaultBannerData.image,
          };

          setBannerData(bannerData);
          setPreviewImage(bannerData.image || defaultBannerData.image);
          console.log('Final banner data:', bannerData);
        } else {
          console.error('Error al descomprimir el banner: decompressedData es null.');
        }
      } catch (error) {
        console.error('Error al descomprimir el banner:', error);
      }
    } else {
      console.log('No se recibió compressedBanner.');
      // Restablecer a valores por defecto
      setBannerData(defaultBannerData);
      setPreviewImage(defaultBannerData.image);
    }
  }, [compressedBanner]);


 
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser); 
    });
    return () => unsubscribe(); 
  }, []);

  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Change detected - Name: ${name}, Value: ${value}`);
    setBannerData((prev) => ({ ...prev, [name]: value }));
    console.log('Updated bannerData:', bannerData);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setPreviewImage(imageUrl);
      setBannerData((prev) => ({ ...prev, image: file }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      console.log('Data to save in banner (including bannerType):', bannerData);
      const userEmail = getAuthenticatedUser();
      const userId = userEmail.replace('@', '_').replace('.', '_');

      let imageUrl = bannerData.image;

      if (bannerData.image instanceof File) {
        // Sube la imagen utilizando la función uploadFile
        imageUrl = await uploadFile(bannerData.image, 'banners');
      }

      const bannerToSave = {
        ...bannerData,
        image: imageUrl,
      };

      // Convierte los datos del banner a una cadena JSON
      console.log('Data to save in banner:', bannerData);
      const bannerJson = JSON.stringify(bannerToSave);

      // Comprime la cadena JSON utilizando compressToBase64
      const banner = LZString.compressToBase64(bannerJson);

      console.log('banner comprimido:', banner);
      
      //const storeName = window.location.hostname.replace('www.', '');
      // Guarda en Firestore utilizando saveToFirestore
      await saveToFirestore(storeName, 'config', { banner });

      // Actualiza el estado local con los datos guardados
      setBannerData(bannerToSave);
      setPreviewImage(imageUrl);

      console.log('Banner guardado con éxito');
      handleClose();
      console.log('Checking if refreshStoreConfig is called');
      if (refreshStoreConfig) {
        refreshStoreConfig();
        console.log('refreshStoreConfig function is not available');
      }
    } catch (error) {
      console.error('Error al guardar el banner:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddStore = () => {
    navigate('/agregar_tienda');
  };

  return loading ? (
    <BannerSkeleton />
  ) : (

    <>
      <div
        className="main-banner"
        style={{ backgroundImage: `url(${previewImage || defaultBannerData.image})` }}
      >
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="main-banner-content">
                <span style={{ color: bannerData.subtitleColor }}>{bannerData.subtitle}</span>
                <h1 style={{ color: bannerData.titleColor }}>{bannerData.title}</h1>
                <p style={{ color: bannerData.descriptionColor }}>{bannerData.description}</p>

                <a
                  href={bannerData.buttonAction}
                  className="btn"
                  style={{
                    backgroundColor: bannerData.buttonColor,
                    color: bannerData.buttonTextColor,
                  }}
                >
                  {bannerData.buttonText}
                </a>
              </div>
            </div>
          </div>
        </div>

        

        {user && (
          <Tooltip title="Editar Banner" arrow>
            <Fab color="primary" aria-label="edit" onClick={handleOpen}>
              <EditIcon />
            </Fab>
          </Tooltip>
        )}

        <BannerEditorModal
          open={open}
          handleClose={handleClose}
          bannerData={bannerData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleImageChange={handleImageChange}
          loading={loading}
        />


      </div>


     


    </>
  );

};

export default MainBanner;
