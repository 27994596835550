import React, { useState, useEffect } from 'react';
import CardServicio from './CardServicio';
import { Fab, Modal, Box, TextField, Button, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import LZString from 'lz-string';
import { db } from '../../firebaseConfig';  // Adjust the path if necessary

// Utility function to get subdomain
const getSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');
  return parts.length > 2 ? parts[0] : 'localhost'; // Default to 'localhost' if no subdomain
};

const Servicios = () => {
  const [serviciosArray, setServiciosArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newServicio, setNewServicio] = useState({ titulo: '', descripcion: '' });
  const subdomain = getSubdomain(); // Get the subdomain to use as the document path

  // Fetch and decompress data from Firebase
  useEffect(() => {
    const fetchServicios = async () => {
      try {
        setIsLoading(true);
        const docRef = doc(db, subdomain, 'config');  // Use subdomain as the document path
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const compressedData = docSnap.data().servicios;
          console.log('Compressed data fetched:', compressedData);

          if (compressedData) {
            const decompressedData = LZString.decompress(compressedData);
            console.log('Decompressed data:', decompressedData);
            setServiciosArray(JSON.parse(decompressedData || '[]')); // Set to an empty array if decompression returns null
          } else {
            setServiciosArray([]); // If no compressed data, set an empty array
          }
        } else {
          console.warn('No document found for subdomain:', subdomain);
          setServiciosArray([]); // If no document found, set an empty array
        }
      } catch (error) {
        console.error('Error fetching services:', error);
        setServiciosArray([]); // On error, set an empty array
      } finally {
        setIsLoading(false);
      }
    };

    fetchServicios();
  }, [subdomain]); // Add subdomain as a dependency to refetch if it changes

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewServicio({ ...newServicio, [name]: value });
  };

  const handleAddServicio = async () => {
    try {
      const updatedServicios = [...serviciosArray, newServicio];
      setServiciosArray(updatedServicios);
      setNewServicio({ titulo: '', descripcion: '' });
      handleCloseModal();

      // Compress and save data to Firebase
      const compressedData = LZString.compress(JSON.stringify(updatedServicios));
      console.log('Compressed data to save:', compressedData);

      await setDoc(doc(db, subdomain, 'config'), { servicios: compressedData }, { merge: true });
      console.log('Service added successfully');
    } catch (error) {
      console.error('Error saving service:', error);
    }
  };

  return (
    <div>
      <div className="text-center py-5">
        <h1 className="text-2xl font-bold">SERVICIOS</h1>
      </div>

      <div className="flex justify-center items-center flex-col">
        {isLoading ? (
          <p>Loading...</p> // Show loading message while fetching data
        ) : serviciosArray.length > 0 ? (
          <div
            className="grid gap-5 px-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
            style={{ width: '990px' }}
          >
            {serviciosArray.map((servicio, index) => (
              <CardServicio
                key={index}
                titulo={servicio.titulo}
                descripcion={servicio.descripcion}
              />
            ))}
          </div>
        ) : (
          <p>No hay servicios disponibles</p> // Show this message if no services are available
        )}
      </div>

      <Tooltip title="Agregar Servicio " arrow>
        <Fab
          color="primary"
          aria-label="add"
          style={{
            position: 'absolute',
            bottom: '-260px',
            right: '20px',
          }}
          onClick={handleOpenModal}
        >
          <AddIcon />
        </Fab>
      </Tooltip>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="add-service-modal"
        aria-describedby="modal-to-add-service"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <h2 id="add-service-modal">Agregar Servicio</h2>
          <TextField
            fullWidth
            margin="normal"
            label="Título"
            name="titulo"
            value={newServicio.titulo}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Descripción"
            name="descripcion"
            value={newServicio.descripcion}
            onChange={handleInputChange}
            multiline
            rows={4}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleAddServicio}
            style={{ marginTop: '16px' }}
          >
            Agregar
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default Servicios;
