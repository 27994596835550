// ModalForm.js
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const Modal = ({ isOpen, onClose, onSubmit, date, setDate, title, setTitle, description, setDescription, image, setImage,tag,setTag }) => {
    
    // Manejador para leer el archivo de imagen y guardarlo en el estado
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result); // Asigna la URL base64 al estado de imagen
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle >Editar Publicación</DialogTitle>
            <DialogContent>
                <TextField
                    label="Fecha"
                    type="date"
                    fullWidth
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    margin="dense"
                />
                <TextField
                    label="Título"
                    type="text"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    margin="dense"
                />
                <TextField
                    label="Tag"
                    type="text"
                    fullWidth
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                    margin="dense"
                />
                <TextField
                    label="Descripción"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    margin="dense"
                />
                <input
                    accept="image/*"
                    type="file"
                    onChange={handleImageChange}
                    style={{ marginTop: '16px' }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancelar
                </Button>
                <Button onClick={onSubmit} color="primary" variant="contained">
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Modal;
