// src/components/TopPanelSlides.js
import React from 'react';

const slides = [
  'Enjoy an extra 20% off select sales styles',
  'Enjoy an extra 20% off select sales styles',
  'Enjoy an extra 20% off select sales styles',
];

const TopPanelSlides = () => (
  <div className="top-panel-slides owl-carousel owl-theme">
    {slides.map((text, index) => (
      <div key={index} className="single-item-box">
        <p>
          <strong>{text}</strong> <a href="#">Read More</a>
        </p>
      </div>
    ))}
  </div>
);

export default TopPanelSlides;
