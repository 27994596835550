import React from 'react';
import { Modal, Box, TextField, Button, Typography, Grid, InputLabel, FormControl, Select, MenuItem} from '@mui/material';

const BannerEditorModal = ({open, handleClose, bannerData, handleChange, handleSubmit, handleImageChange, loading}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Editar Banner
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            {/* Sección de Subtítulo */}
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Subtítulo"
                name="subtitle"
                value={bannerData.subtitle}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Color del Subtítulo</InputLabel>
              <input
                type="color"
                name="subtitleColor"
                value={bannerData.subtitleColor}
                onChange={handleChange}
                style={{ width: '100%', height: '40px', border: 'none' }}
              />
            </Grid>

            {/* Sección de Título */}
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Título"
                name="title"
                value={bannerData.title}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Color del Título</InputLabel>
              <input
                type="color"
                name="titleColor"
                value={bannerData.titleColor}
                onChange={handleChange}
                style={{ width: '100%', height: '40px', border: 'none' }}
              />
            </Grid>

            {/* Sección de Descripción */}
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Descripción"
                name="description"
                value={bannerData.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Color de la Descripción</InputLabel>
              <input
                type="color"
                name="descriptionColor"
                value={bannerData.descriptionColor}
                onChange={handleChange}
                style={{ width: '100%', height: '40px', border: 'none' }}
              />
            </Grid>

            {/* Configuración del Botón */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Texto del Botón"
                name="buttonText"
                value={bannerData.buttonText}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="URL de Acción del Botón"
                name="buttonAction"
                value={bannerData.buttonAction}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <InputLabel>Color del Botón</InputLabel>
              <input
                type="color"
                name="buttonColor"
                value={bannerData.buttonColor}
                onChange={handleChange}
                style={{ width: '100%', height: '40px', border: 'none' }}
              />
            </Grid>
            <Grid item xs={2}>
              <InputLabel>Color del Texto del Botón</InputLabel>
              <input
                type="color"
                name="buttonTextColor"
                value={bannerData.buttonTextColor}
                onChange={handleChange}
                style={{ width: '100%', height: '40px', border: 'none' }}
              />
            </Grid>

            {/* Tipo de Banner */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Tipo de Banner</InputLabel>
                <Select
                  label="Tipo de Banner"
                  name="bannerType"
                  value={bannerData.bannerType}
                  onChange={handleChange}
                >
                  <MenuItem value="MainBanner">MainBanner</MenuItem>
                  <MenuItem value="MainBanner2">MainBanner2</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Subir Imagen */}
            <Grid item xs={12}>
              <Button variant="contained" component="label" fullWidth>
                Subir Imagen
                <input type="file" hidden accept="image/*" onChange={handleImageChange} />
              </Button>
            </Grid>

            {/* Botón para guardar */}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                disabled={loading}
              >
                {loading ? 'Guardando...' : 'Guardar Cambios'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default BannerEditorModal;