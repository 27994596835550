// src/components/ProductFormModal.js

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Chip,
  Stack,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import IconButton from '@mui/material/IconButton';
import { functions } from '../../firebaseConfig'; // Asegúrate de que la ruta sea correcta
import { httpsCallable } from 'firebase/functions';

const ProductFormModal = ({ open, handleClose, handleSubmit, productData = null }) => {


  const [formData, setFormData] = useState({
    id: '',
    name: '',
    price: '',
    oldPrice: '',
    vendor: '',
    availability: '',
    productType: '',
    paymentLink: '',
    colors: [],          // Lista de colores
    sizes: [],           // Lista de tamaños
    images: [],          // URLs de imágenes del producto
    description: '',     // Descripción detallada del producto
    seo: {               // Información SEO
      metaTitle: '',
      metaDescription: '',
      keywords: [],      // Lista de keywords SEO
      altText: [],       // Lista de textos alternativos para las imágenes
      productUrl: '',    // URL del producto
    },
    material: '',        // Material del producto
    careInstructions: '', // Instrucciones de cuidado del producto
    schema: {            // Esquema para Schema.org
      "@context": "https://schema.org",
      "@type": "Product",
      name: '',
      description: '',
      brand: {
        "@type": "Brand",
        name: '',
      },
      offers: {
        "@type": "Offer",
        url: '',
        priceCurrency: 'USD',
        price: '',
        availability: 'https://schema.org/InStock'
      }
    }
  });
  
  useEffect(() => {
    if (productData) {
      setFormData({
        id: productData.id || '',
        name: productData.name || '',
        price: productData.price || '',
        oldPrice: productData.oldPrice || '',
        vendor: productData.vendor || '',
        availability: productData.availability || '',
        productType: productData.productType || '',
        paymentLink: productData.paymentLink || '',
        colors: productData.colors || [],
        sizes: productData.sizes || [],
        images: productData.images || [],
        description: productData.description || '',
        seo: {
          metaTitle: productData.seo?.metaTitle || '',
          metaDescription: productData.seo?.metaDescription || '',
          keywords: productData.seo?.keywords || [],
          altText: productData.seo?.altText || [],
          productUrl: productData.seo?.productUrl || '',
        },
        material: productData.material || '',
        careInstructions: productData.careInstructions || '',
        schema: {
          "@context": productData.schema?.["@context"] || "https://schema.org",
          "@type": productData.schema?.["@type"] || "Product",
          name: productData.schema?.name || '',
          description: productData.schema?.description || '',
          brand: {
            "@type": productData.schema?.brand?.["@type"] || "Brand",
            name: productData.schema?.brand?.name || '',
          },
          offers: {
            "@type": productData.schema?.offers?.["@type"] || "Offer",
            url: productData.schema?.offers?.url || '',
            priceCurrency: productData.schema?.offers?.priceCurrency || 'USD',
            price: productData.schema?.offers?.price || '',
            availability: productData.schema?.offers?.availability || 'https://schema.org/InStock'
          }
        }
      });
    } else {
      resetForm();
    }
  }, [productData, open]);


  const resetForm = () => {
      setFormData({
        id: '',
        name: '',
        price: '',
        oldPrice: '',
        vendor: '',
        availability: '',
        productType: '',
        paymentLink: '',
        colors: [],
        sizes: [],
        images: [],
        description: '',
        seo: {
          metaTitle: '',
          metaDescription: '',
          keywords: [],
          altText: [],
          productUrl: '',
        },
        material: '',
        careInstructions: '',
        schema: {
          "@context": "https://schema.org",
          "@type": "Product",
          name: '',
          description: '',
          brand: {
            "@type": "Brand",
            name: '',
          },
          offers: {
            "@type": "Offer",
            url: '',
            priceCurrency: 'USD',
            price: '',
            availability: 'https://schema.org/InStock'
          }
        }
      });
    };



  const storage = getStorage();
  const [currentColor, setCurrentColor] = useState('');
  const [currentSize, setCurrentSize] = useState('');
  const [currentKeyword, setCurrentKeyword] = useState('');
  const [loading, setLoading] = useState(false);  // Para manejar el estado de carga
  const [error, setError] = useState(null);  // Para manejar errores

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddColor = () => {
    if (currentColor) {
      setFormData((prev) => ({
        ...prev,
        colors: [...prev.colors, currentColor],
      }));
      setCurrentColor('');
    }
  };

  const handleRemoveColor = (color) => {
    setFormData((prev) => ({
      ...prev,
      colors: prev.colors.filter((c) => c !== color),
    }));
  };

  const handleAddSize = () => {
    if (currentSize) {
      setFormData((prev) => ({
        ...prev,
        sizes: [...prev.sizes, currentSize],
      }));
      setCurrentSize('');
    }
  };

  const handleRemoveSize = (size) => {
    setFormData((prev) => ({
      ...prev,
      sizes: prev.sizes.filter((s) => s !== size),
    }));
  };

  

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formData);
    handleClose();
  };

  const handleRemoveImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index),
    }));
  };

  const handleAddKeyword = () => {
    if (currentKeyword) {
      setFormData((prev) => ({
        ...prev,
        seo: {
          ...prev.seo,
          keywords: [...prev.seo.keywords, currentKeyword],
        },
      }));
      setCurrentKeyword('');
    }
  };

  const handleRemoveKeyword = (keyword) => {
    setFormData((prev) => ({
      ...prev,
      seo: {
        ...prev.seo,
        keywords: prev.seo.keywords.filter((k) => k !== keyword),
      },
    }));
  };
  
  // Función para generar descripción con IA
  const generateProductDescription = async () => {
    setLoading(true);  // Estado de carga
    setError(null);  // Resetear error

    const generateDescriptionFunction = httpsCallable(functions, 'generateProductDescriptionWithAI');

    try {
 
      //const result = await generateDescriptionFunction({ producto: formData, idioma: 'español' }); 
      //console.log("descripcionPorImagen result",result); 
      //const descripcionPorImagen = result.descripcionPorImagen;
     
      
      //console.log("descripcionPorImagen.description",descripcionPorImagen);
      // Actualizar el formulario con los datos obtenidos
      /*setFormData((prev) => ({
        ...prev,
        description: descripcionPorImagen.description,
        seo: {
          ...prev.seo,
          metaTitle: descripcionPorImagen.seo.metaTitle,
          metaDescription: descripcionPorImagen.seo.metaDescription,
          keywords: descripcionPorImagen.seo.keywords,  // Actualizar keywords con los resultados obtenidos
          altText: descripcionPorImagen.seo.altText,
          productUrl: descripcionPorImagen.seo.productUrl
        },
        material: descripcionPorImagen.material,
        careInstructions: descripcionPorImagen.careInstructions,
      }));*/

      setLoading(false);  // Terminar el estado de carga
    } catch (err) {
      setError(err.message);
      setLoading(false);  // Terminar el estado de carga
    }
  };

  // Función para subir imágenes a Firebase Storage
  const uploadImageToStorage = async (file) => {
    const storageRef = ref(storage, `products/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on('state_changed',
        (snapshot) => {
          // Progreso de la carga (opcional)
        },
        (error) => reject(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => resolve(downloadURL))
            .catch((error) => reject(error));
        }
      );
    });
  };

  // Manejar imágenes y subirlas a Firebase Storage
  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    setLoading(true); // Establecer el estado de carga a verdadero

    try {
      const imageUrls = await Promise.all(files.map((file) => uploadImageToStorage(file)));
      
      setFormData((prev) => ({
        ...prev,
        images: [...prev.images, ...imageUrls],
      }));

      setLoading(false); // Terminar el estado de carga
    } catch (error) {
      console.error('Error al cargar las imágenes:', error);
      setLoading(false); // Terminar el estado de carga
    }
  };


  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          maxHeight: '90vh', // Establecemos la altura máxima
          overflowY: 'auto',   // Permitimos el desplazamiento vertical
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" gutterBottom>
          {productData ? 'Editar Producto' : 'Registrar Producto'}
        </Typography>
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>

          <Grid item xs={12} md={6}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre del Producto"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripción"
                name="description"
                value={formData.description}
                onChange={handleChange}
                multiline
                rows={4}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Precio"
                name="price"
                type="number"
                value={formData.price}
                onChange={handleChange}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Precio Anterior"
                name="oldPrice"
                type="number"
                value={formData.oldPrice}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Vendedor"
                name="vendor"
                value={formData.vendor}
                onChange={handleChange}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Disponibilidad"
                name="availability"
                value={formData.availability}
                onChange={handleChange}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Tipo de Producto"
                name="productType"
                value={formData.productType}
                onChange={handleChange}
                required
              />
            </Grid>
          
          </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
          <Grid container spacing={2}>

            

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Enlace de Pago"
                name="paymentLink"
                value={formData.paymentLink}
                onChange={handleChange}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Agregar Color"
                value={currentColor}
                onChange={(e) => setCurrentColor(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleAddColor}
                startIcon={<AddIcon />}
              >
                Agregar Color
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                {formData.colors.map((color, index) => (
                  <Chip
                    key={index}
                    label={color}
                    onDelete={() => handleRemoveColor(color)}
                    color="primary"
                  />
                ))}
              </Stack>
            </Grid>



            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Agregar Tamaño"
                value={currentSize}
                onChange={(e) => setCurrentSize(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleAddSize}
                startIcon={<AddIcon />}
              >
                Agregar Tamaño
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                {formData.sizes.map((size, index) => (
                  <Chip
                    key={index}
                    label={size}
                    onDelete={() => handleRemoveSize(size)}
                    color="primary"
                  />
                ))}
              </Stack>
            </Grid>

            
            
          </Grid>
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Agregar Palabra Clave (Keyword)"
              value={currentKeyword}
              onChange={(e) => setCurrentKeyword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleAddKeyword}
              startIcon={<AddIcon />}
            >
              Agregar Palabra Clave
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              {formData.seo.keywords.map((keyword, index) => (
                <Chip
                  key={index}
                  label={keyword}
                  onDelete={() => handleRemoveKeyword(keyword)}
                  color="primary"
                />
              ))}
            </Stack>
          </Grid>


          <Grid item xs={12} md={12}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Button
                variant="contained"
                component="label"
                startIcon={<PhotoCamera />}
                fullWidth
              >
                Subir Imágenes
                <input
                  type="file"
                  hidden
                  multiple
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                {formData.images.map((image, index) => (
                  <div key={index} style={{ position: 'relative' }}>
                    <img
                      src={image}
                      alt={`product-${index}`}
                      style={{ width: 50, height: 50, objectFit: 'cover' }}
                    />
                    <IconButton
                      onClick={() => handleRemoveImage(index)}
                      size="small"
                      style={{
                        position: 'absolute',
                        top: -10,
                        right: -10,
                        backgroundColor: 'rgba(255,255,255,0.7)',
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </div>
                ))}
              </Stack>
            </Grid>
            
            {/* Botón para generar la descripción con IA */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={generateProductDescription}
                fullWidth
                disabled={loading}
              >
                {loading ? 'Generando Descripción...' : 'Generar Descripción con IA'}
              </Button>
            </Grid>

            {/* Mostrar error si lo hay */}
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                {productData ? 'Guardar Cambios' : 'Registrar Producto'}
              </Button>
            </Grid>
          
          </Grid>
          </Grid>


          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default ProductFormModal;
