import React, { useState,useEffect } from 'react';
import Modal from '../../components/common/Modal/Modal';
import Button from '@mui/material/Button';
import { db, collection, addDoc, getDocs  } from '../../firebaseConfig'; // Importa la configuración de Firebase
import { useLocation } from 'react-router-dom';

const Blog = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [date, setDate] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [tag, setTag] = useState('');
    const [posts, setPosts] = useState([]);

    const location = useLocation();

    // Extraer el subdominio del hostname
    const storeName = window.location.hostname.split('.')[0]; // Esto toma el primer segmento del dominio como subdominio
    

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const savePostToFirestore = async (newPost) => {
        try {
            const docRef = await addDoc(collection(db, `${storeName}/blog/entry`), newPost);
            console.log("Post guardado en Firestore con ID:", docRef.id);
            newPost.id = docRef.id;
            return newPost;
        } catch (error) {
            console.error("Error guardando el post en Firestore:", error.message);
        }
    };

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, `${storeName}/blog/entry`));
                const postsFromFirestore = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setPosts(postsFromFirestore);
            } catch (error) {
                console.error("Error al obtener las publicaciones de Firestore:", error.message);
            }
        };

        fetchPosts();
    }, [storeName]);
    
    // Manejo del envío del formulario
    /*const handleSubmit = async (e) => {
        e.preventDefault();

        const newPost = {
            date,
            title,
            description,
            image
        };

        setPosts([...posts, newPost]);

        // Guardar en Firestore
        await savePostToFirestore(newPost);

        // Limpiar los campos del formulario
        setDate('');
        setTitle('');
        setDescription('');
        setImage('');

        closeModal();
    };*/

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newPost = {
            date,
            title,
            description,
            image,
            tag
        };

        // Guarda en Firestore y obtén el nuevo post con ID
        const savedPost = await savePostToFirestore(newPost);

        if (savedPost) {
            setPosts([...posts, savedPost]);

            // Limpiar los campos del formulario
            setDate('');
            setTitle('');
            setDescription('');
            setImage('');
            setTag('');
            closeModal();
        }
    };


    return (
        <>
            <section style={{ padding: '60px 0' }}>

            <div className="flex justify-center items-center mb-5">
    <p className='text-black' style={{ fontSize: '28px', fontWeight: 'bold' }}>Blog</p>
</div>

                <div style={{ width: '100%', margin: '0 auto', padding: '0 15px', maxWidth: '1536px'}}>
                    <div style={{ 
                        display: 'grid', 
                        gridTemplateColumns: 'repeat(3, 1fr)', 
                        gap: '20px', 
                        marginBottom: '20px' 
                    }}>
                        {posts.map((post) => (
                            <div className="flex w-full items-center justify-center">

                            
                            <div key={post.id} style={{
                                backgroundColor: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                                transition: 'transform 0.3s ease',
                                maxWidth: '300px',
                                width: '100%',
                                height: '390px'
                            }}>
                                <div style={{ position: 'relative', width: '100%', maxWidth: '340px', maxHeight: '250px', overflow: 'hidden' }}>
                                        <img 
                                            src={post.image || "assets/img/blog5.jpg"} 
                                            alt="Post"
                                            style={{ width: '100%', height: '250px' }} 
                                        />
                                        <div style={{
                                            position: 'absolute',
                                            top: '10px',
                                            left: '10px',
                                            backgroundColor: '#fff  ',
                                            color: '#000',
                                            padding: '5px 10px',
                                            borderRadius: '4px',
                                            fontSize: '12px'
                                        }}>
                                            <a href="#" style={{ color: '#000', textDecoration: 'none' }}>{post.tag}</a>
                                        </div>
                                    </div>

                                <div style={{ padding: '15px' }}>
                                    <span style={{ display: 'block', marginBottom: '10px', color: '#888' }}>{post.date}</span>
                                    <h3 style={{ margin: '0 0 10px', fontSize: '18px' }}>
                                        <a href="#" style={{ color: '#333', textDecoration: 'none',fontWeight: 'bold'  }}>{post.title}</a>
                                    </h3>
                                    <p className='line-clamp-2'  style={{ margin: '0 0 15px', color: '#666' }}>{post.description}</p>
                                    <a href="#" style={{ color: '#007bff', textDecoration: 'none', fontWeight: 'bold' }}>
                                        Leer más <i className="icofont-double-right"></i>
                                    </a>
                                </div>
                            </div>

                            </div>
                        ))}
                    </div>

                    <div style={{ textAlign: 'center', marginTop: '30px' }}>
                        <Button variant="contained" color="primary" onClick={openModal}>
                            Agregar Publicación
                            
                        </Button>
                       
                    </div>

                    <Modal
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        onSubmit={handleSubmit}
                        date={date}
                        setDate={setDate}
                        title={title}
                        setTitle={setTitle}
                        description={description}
                        setDescription={setDescription}
                        image={image}
                        setImage={setImage}
                        tag={tag}
                        setTag={setTag}
                    />
                </div>
            </section>
        </>
    );
};

export default Blog;
