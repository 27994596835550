import React, { useState, useEffect } from 'react';
import './Gallery.css'; // Estilos opcionales

const ProductGallery = ({ product }) => {
  // Verifica si product.images existe, de lo contrario, usa un arreglo vacío
  const images = product?.images || [];
  
  // Estado para la imagen seleccionada, por defecto la primera imagen si existe
  const [selectedImage, setSelectedImage] = useState(images[0]);

  // Si las imágenes cambian en el futuro, actualiza la imagen seleccionada
  useEffect(() => {
    if (images.length > 0) {
      setSelectedImage(images[0]);
    }
  }, [images]);

  if (images.length === 0) {
    return <p>No hay imágenes disponibles.</p>;
  }

  return (
    <div className="products-page-gallery">
      {/* Imagen principal grande */}
      <div className="product-page-gallery-main">
        <div className="item">
          <img src={selectedImage} alt="Selected Product" />
        </div>
      </div>

      {/* Previsualizaciones pequeñas debajo */}
      <div className="product-page-gallery-preview">
        {images.map((img, index) => (
          <div
            className={`item ${img === selectedImage ? 'active' : ''}`}
            key={index}
            onClick={() => setSelectedImage(img)}
          >
            <img src={img} alt={`Preview ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductGallery;
