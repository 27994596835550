// src/hooks/useStore.js
import { useState ,useEffect} from 'react';
import { 
  uploadFile, 
  saveToFirestore, 
  getAuthenticatedUser, 
  getDocuments,
  checkDocumentExists,
  getDocumentById  
} from '../utils/firebaseUtils'; // Importar funciones reutilizables
import { arrayUnion } from 'firebase/firestore'; // Importar arrayUnion
import { getFirestore, collection, getDocs, query, where,doc ,getDoc} from "firebase/firestore"; 
import { getAuth,  setPersistence, browserLocalPersistence, onAuthStateChanged  } from "firebase/auth"; 
import LZString from 'lz-string';


const db = getFirestore();
const auth = getAuth();

// Configuración de persistencia de autenticación
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Configura el dominio de las cookies en el dominio raíz
    auth.settings.appVerificationDisabledForTesting = true;  // Solo para desarrollo local
    auth.settings.domain = '.mispacecode.com';  // Dominio raíz para todos los subdominios
    console.log("Persistencia de sesión configurada correctamente.");
  })
  .catch((error) => {
    console.error("Error al configurar la persistencia de sesión:", error);
  });


const useStore = () => {
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [stores, setStores] = useState([]);
  const [storeConfig, setStoreConfig] = useState(null);
  const [authenticatedUser, setAuthenticatedUser] = useState(null);

  useEffect(() => {
    // Detectar cambios en el estado de autenticación
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticatedUser(user.email);
      } else {
        setAuthenticatedUser(null);
      }
    });
    
    // Limpiar el observador al desmontar el componente
    return () => unsubscribe();
  }, []);



  // Función para manejar errores y carga de forma eficiente
  const executeWithLoading = async (fn) => {
    setLoading(true);
    setError(null);
    try {
      return await fn();
    } catch (err) {
      //console.error(err);
      setError(err.message);
      return { success: false, error: err.message };
    } finally {
      setLoading(false);
    }
  };

  // Función para registrar la tienda
  const registerStore = async (storeData) =>
  executeWithLoading(async () => {
    const storeName = storeData.title.replace(/\s+/g, "_");

    // Verificar si la tienda ya existe usando la función genérica
    const storeExists = await checkDocumentExists(storeName, "config");
    if (storeExists) {
      throw new Error("La tienda ya está registrada");
    }

    const email = getAuthenticatedUser();

    // Subir el logo a la carpeta 'logos'
    const logo = await uploadFile(storeData.logo, "logos");

    // Crear el footer basado en los datos ingresados
    const footer = storeData.footer || "© 2024 Mi Tienda. Todos los derechos reservados.";

    // Guardar la tienda en la colección del usuario autenticado
    await saveToFirestore("registered-users", email, {
      stores: arrayUnion(storeName),
    });

    // Crear registro de tienda con logo, footer y creador
    await saveToFirestore(storeName, "config", {
      "created-by": email,
      logo,
      footer,
    });

    return { success: true };
  });


    
    
  const getStores = async () => {
      
      const userEmail = authenticatedUser;
      
      if (!userEmail) {
        console.error("No hay usuario autenticado");
        return [];
      }
    
      try {
        // Referencia al documento del usuario dentro de "registered-users"
        const userDocRef = doc(db, "registered-users", userEmail);
        
        // Obtener el documento del usuario
        const userDoc = await getDoc(userDocRef);
    
        if (!userDoc.exists()) {
          console.error("No se encontró el documento del usuario");
          return [];
        }
    
        // Extraer el campo "stores" del documento
        const userData = userDoc.data();
        const userStores = userData.stores || [];
    
        //console.log("Tiendas del usuario:", userStores);
        return userStores;
      } catch (error) {
        console.error("Error al obtener las tiendas:", error);
        return [];
      }
  };


  // Función para obtener la configuración de una tienda por su nombre (dominio)
  const getStoreConfig = async (storeName) =>
    executeWithLoading(async () => {
      try {
        const config = await getDocumentById(storeName, 'config'); // Llamamos a la función genérica
        if (config) {
          setStoreConfig(config);
          return { success: true, data: config };
        } else {
          throw new Error('No se encontró la configuración de la tienda');
        }
      } catch (err) {
        console.error('Error al obtener la configuración de la tienda:', err);
        throw err;
      }
    });


  return { registerStore, getStores, stores, loading, error , getStoreConfig, storeConfig ,authenticatedUser};
};

export default useStore;
