import React, { useState,useEffect } from 'react';
import { getAuth ,signInWithPopup, signOut } from 'firebase/auth';
import { auth, googleProvider } from '../../firebaseConfig';
import { useNavigate } from 'react-router-dom'; 
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore'; // Importa Firestore

const db = getFirestore()

const LoginForm = () => {
  
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '', password: '' });

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Submitted:', formData);
   
  };

  const loginWithGoogle = async () => {
    googleProvider.setCustomParameters({ prompt: 'select_account' });

    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      console.log('Usuario autenticado(logueado):', user);

      // Verifica si el usuario ya está registrado en Firestore
      const userRef = doc(db, 'registered-users', user.email);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        // Si no existe, regístralo en la colección 'registered-users'
        await setDoc(userRef, {
          email: user.email,        
        });
        console.log('Usuario registrado en Firestore:', user.email);
      } else {
        console.log('El usuario ya está registrado en Firestore:', user.email);
      }

      navigate('/admi_tienda');
    } catch (error) {
      console.error('Error en la autenticación:', error);
    }
  };

  return (
    <div className="login-content">
      <div className="section-title">
        <h2>
          <span className="dot"></span> Login
        </h2>
      </div>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter your email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Enter your password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        
        <button type="submit" className="btn btn-primary">
          Login
        </button>
        
        <button type="button" className="btn btn-primary mt-2" onClick={loginWithGoogle}>
          Continuar con Google
        </button>
        
        <a href="#" className="forgot-password"> Lost your password? </a>
      </form>
    </div>
  );
};

export default LoginForm;
