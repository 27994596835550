import React from 'react';

const CardServicio = ({ titulo, descripcion }) => {
  return (

    <div className='p-3  rounded-lg flex items-start flex-col gap-2 transition-transform transition-shadow duration-300 hover:shadow-2xl hover:-translate-y-2'>
        <div className='flex justify-center items-center bg-[#004cd3] rounded-lg w-[40px] h-[40px]'>
            <p>S</p>
        </div>
        <div>
            <p className='text-left font-bold text-sm tex-[20px] text-black'>{titulo}</p>
            <p className='text-left font-normal text-base text-black max-h-[100px] min-h-[100px] line-clamp-4'>{descripcion}</p>
        </div>
    </div>
  );
};

export default CardServicio;
