import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../../../firebaseConfig.js';
import MobileNav from './MobileNav';
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Avatar,
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';


const Navbar = () => {
  
  const [user, setUser] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [logo, setLogo] = useState(`${process.env.PUBLIC_URL}/assets/img/Millev.png`); // Estado del logo actual
  const [newLogo, setNewLogo] = useState(null); 
  const navigate = useNavigate();
  const dropdownRef = useRef(null);


  
  // Manejar cambios en la autenticación del usuario
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  // Abrir y cerrar el diálogo
  const handleOpenDialog = (event) => {
    event.preventDefault();
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setNewLogo(null); // Limpiar la selección del nuevo logo
  };

  // Manejar la selección del archivo
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setNewLogo(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  // Guardar el nuevo logo
  const handleSaveLogo = () => {
    if (newLogo) setLogo(newLogo);
    handleCloseDialog();
  };

  // Cerrar sesión
  const handleLogout = () => {
    signOut(auth).then(() => navigate('/')).catch(console.error);
  };

  // Navegar a administración
  const handleAdmin = () => navigate('/admi_tienda');

  // Abrir y cerrar el dropdown
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  // Cerrar el dropdown si se hace clic fuera de él
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="navbar-area">
      <MobileNav />
      <div className="comero-nav">
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-light">
            <a className="navbar-brand" href="index.html">
              <Box
                onMouseEnter={() => setShowEditIcon(true)}
                onMouseLeave={() => setShowEditIcon(false)}
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              >
                {/* Logo */}
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: '98px', height: '20px' }}
                />

                {/* Icono de edición */}
                {showEditIcon && (
                  <IconButton
                    size="small"
                    onClick={handleOpenDialog}
                    sx={{
                      backgroundColor: 'white',
                      boxShadow: 1,
                      '&:hover': { backgroundColor: 'lightgrey' },
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
            </a>

            <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
              <div className="others-option">
                {user ? (
                  <div className="option-item" style={{ position: 'relative' }} ref={dropdownRef}>
                    <div
                      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                      onClick={toggleDropdown}
                    >
                      <Avatar
                        src={user.photoURL}
                        alt={user.displayName}
                        sx={{ width: 40, height: 40 }}
                      />
                      <i
                        className={`fas fa-chevron-down ${dropdownOpen ? 'rotate' : ''}`}
                        style={{ marginLeft: '5px' }}
                      ></i>
                    </div>

                    {dropdownOpen && (
                      <div
                        className="dropdown-menu show"
                        style={{
                          position: 'absolute',
                          top: '100%',
                          right: 0,
                          backgroundColor: 'white',
                          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                          borderRadius: '8px',
                          padding: '10px',
                          zIndex: 1000,
                        }}
                      >
                        <p style={{ margin: 0 }}>{user.displayName}</p>
                        <button
                          onClick={handleAdmin}
                          className="btn btn-link"
                          style={{
                            padding: 0,
                            marginTop: '10px',
                            color: '#3b49de',
                            fontSize: '12px',
                            textDecoration: 'none',
                          }}
                        >
                          Administrar tiendas
                        </button>
                        <button
                          onClick={handleLogout}
                          className="btn btn-link"
                          style={{
                            padding: 0,
                            marginTop: '10px',
                            color: 'red',
                            fontSize: '12px',
                            textDecoration: 'none',
                          }}
                        >
                          Cerrar sesión
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="option-item"></div>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>

      {/* Dialog para subir el nuevo logo */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="xs" fullWidth>
        <DialogTitle>Editar Logo</DialogTitle>
        <DialogContent>
          <Stack spacing={2} alignItems="center">
            <Box
              component="img"
              src={newLogo || logo}
              alt="Vista Previa"
              sx={{
                width: '100%',
                maxHeight: 150,
                objectFit: 'contain',
              }}
            />
            <Button variant="outlined" component="label" fullWidth>
              Seleccionar Imagen
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={handleSaveLogo}
            color="primary"
            variant="contained"
            disabled={!newLogo}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Navbar;
