// src/components/FacilityArea.js
import React from 'react';
import FacilityBox from './FacilityBox';

const facilities = [
  { icon: 'fas fa-plane', title: 'Free Shipping World Wide' },
  { icon: 'fas fa-money-check-alt', title: '100% money back guarantee' },
  { icon: 'far fa-credit-card', title: 'Many payment gateways' },
  { icon: 'fas fa-headset', title: '24/7 online support' },
];

const FacilityArea = () => (
  <section className="facility-area">
    <div className="container">
      <div className="row">
        {facilities.map((facility, index) => (
          <FacilityBox key={index} {...facility} />
        ))}
      </div>
    </div>
  </section>
);

export default FacilityArea;
